import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      height={80}
      width={80}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M481.081 438.5H30.919C13.843 438.5 0 424.657 0 407.581V137.102h512v270.479c0 17.076-13.843 30.919-30.919 30.919z"
        fill="#a9dbf5"
      />
      <path
        d="M481.095 137.102V438.5c17.069 0 30.905-13.849 30.905-30.932V137.102z"
        fill="#88c3e0"
      />
      <g fill="#43809f">
        <path d="M103.87 233.967a7.501 7.501 0 00-10.606 0l-46.431 46.431a7.5 7.5 0 000 10.607l45.816 45.816c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.196a7.5 7.5 0 000-10.607l-40.513-40.513 41.127-41.127a7.5 7.5 0 00.001-10.607zM264.52 280.397l-46.431-46.431a7.501 7.501 0 00-10.606 10.607L248.61 285.7l-40.513 40.513a7.5 7.5 0 0010.606 10.607l45.817-45.816a7.502 7.502 0 000-10.607zM185.813 206.063a7.497 7.497 0 00-9.673 4.351l-54.902 144.638a7.5 7.5 0 1014.023 5.322l54.902-144.638a7.5 7.5 0 00-4.35-9.673z" />
      </g>
      <path
        d="M386.318 234.23h-48.419c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h48.419c8.534 0 15.453 6.918 15.453 15.453 0 8.534-6.919 15.453-15.453 15.453z"
        fill="#29cef6"
      />
      <path
        d="M465.642 296.041H337.899c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h127.742c8.534 0 15.453 6.918 15.453 15.453.001 8.534-6.918 15.453-15.452 15.453z"
        fill="#ffc328"
      />
      <path
        d="M427.695 357.852h-89.796c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h89.796c8.534 0 15.453 6.918 15.453 15.453 0 8.534-6.919 15.453-15.453 15.453z"
        fill="#f78e36"
      />
      <path
        d="M512 147.737H0v-43.318C0 87.343 13.843 73.5 30.919 73.5h450.162C498.157 73.5 512 87.343 512 104.419z"
        fill="#43809f"
      />
      <path
        d="M481.095 73.5v74.237H512v-43.305c0-17.083-13.837-30.932-30.905-30.932z"
        fill="#3a7190"
      />
      <circle cx={49.455} cy={111.126} fill="#29cef6" r={15.453} />
      <circle cx={109.37} cy={111.126} fill="#f3f3f3" r={15.453} />
      <circle cx={169.285} cy={111.126} fill="#f78e36" r={15.453} />
    </svg>
  )
}

export default SvgComponent