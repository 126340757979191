import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={80}
      width={80}
      viewBox="0 0 512 512"

      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M308.105 449.155l-21.121-99.736a21.523 21.523 0 01.849-11.864l28.755-78.397c4.091-11.154 16.449-16.881 27.603-12.789 11.154 4.091 16.879 16.449 12.789 27.603l-26.624 72.588 19.839 93.682c2.461 11.623-4.966 23.04-16.589 25.501-11.627 2.461-23.04-4.966-25.501-16.588z"
        fill="#748488"
      />
      <path
        d="M380.423 466.158c-11.881 0-21.512-9.631-21.512-21.512v-157.78c0-11.881 9.631-21.512 21.512-21.512s21.512 9.631 21.512 21.512v157.78c-.001 11.881-9.632 21.512-21.512 21.512z"
        fill="#55696e"
      />
      <path
        d="M466.09 451.245l-17.972-75.92a19.379 19.379 0 01-.521-4.462v-78.916c0-10.698 8.673-19.371 19.371-19.371s19.371 8.673 19.371 19.371v76.654l17.451 73.72c2.465 10.41-3.978 20.848-14.388 23.312-10.412 2.467-20.848-3.981-23.312-14.388z"
        fill="#ffb69e"
      />
      <path
        d="M406.969 466.141c-10.689-.456-18.985-9.49-18.529-20.178l3.481-81.712c.06-1.398.27-2.784.629-4.136l19.371-73.124c2.739-10.341 13.343-16.505 23.686-13.764 10.341 2.74 16.504 13.344 13.764 23.686l-18.832 71.09-3.392 79.61c-.454 10.661-9.46 18.979-20.178 18.528z"
        fill="#ffb69e"
      />
      <path
        d="M206.363 207.818c-1.203-10.155 6.053-19.362 16.208-20.565l72.43-8.582 53.854-37.173c8.417-5.808 19.948-3.696 25.756 4.72 5.809 8.416 3.696 19.947-4.72 25.756l-57.634 39.782a18.52 18.52 0 01-8.34 3.149l-76.99 9.122c-10.131 1.2-19.359-6.03-20.564-16.209z"
        fill="#ba6746"
      />
      <circle cx={363.222} cy={82.939} fill="#ba6746" r={37.153} />
      <path
        d="M13.323 464.452c-10.985-4.525-16.221-17.099-11.695-28.084l35.842-86.994 2.71-64.749c.497-11.87 10.523-21.082 22.392-20.593 11.87.496 21.09 10.522 20.593 22.392l-2.868 68.537a21.506 21.506 0 01-1.603 7.295l-37.286 90.5c-4.495 10.909-17.028 16.251-28.085 11.696z"
        fill="#5f6e9b"
      />
      <path
        d="M120.947 446.442l-7.666-91.469-27.892-81.494c-3.847-11.24 2.146-23.471 13.386-27.318 11.239-3.85 23.472 2.146 27.318 13.386l28.755 84.012a21.553 21.553 0 011.084 5.169l7.888 94.122c.992 11.839-7.801 22.24-19.64 23.233-11.84.988-22.241-7.797-23.233-19.641z"
        fill="#414b82"
      />
      <path
        d="M110.006 131.659H65.25c-13.861 0-25.098 11.237-25.098 25.098v128.769h94.952v-128.77c0-13.861-11.237-25.097-25.098-25.097z"
        fill="#64afff"
      />
      <circle cx={87.823} cy={82.939} fill="#ffb69e" r={37.153} />
      <path
        d="M226.238 205.667c-.575-10.209-9.314-18.002-19.527-17.445l-55.301-3.208-22.01-15.162-15.962 11.335-3.269 20.447 24.935 17.266a18.51 18.51 0 0011.851 3.454l61.838 2.84c10.209-.574 18.02-9.317 17.445-19.527z"
        fill="#ffb69e"
      />
      <path
        d="M129.399 169.853l-32.292-23.222c-8.303-5.97-19.872-4.081-25.842 4.222-5.97 8.302-4.08 19.872 4.222 25.842l34.682 24.941c0-.001 19.179-31.44 19.23-31.783z"
        fill="#4699f2"
      />
      <path
        d="M376.845 131.659h-44.757c-13.861 0-25.098 11.237-25.098 25.098v128.769h94.952v-128.77c.001-13.861-11.236-25.097-25.097-25.097z"
        fill="#ffda52"
      />
      <g>
        <path
          d="M503.519 159.166l-37.715-24.329a18.515 18.515 0 00-9.984-2.956l-34.498-.099-5.279 37.055 34.246.059 33.157 21.389a18.429 18.429 0 0010.019 2.958c6.08 0 12.036-2.992 15.577-8.481 5.543-8.593 3.07-20.053-5.523-25.596z"
          fill="#ba6746"
        />
        <path
          d="M421.322 131.781l-42.501-.122-.054-.001c-10.2 0-18.485 8.255-18.514 18.462-.029 10.226 8.236 18.539 18.462 18.568l42.607.122z"
          fill="#ffda52"
        />
      </g>
      <circle
        cx={438.242}
        cy={91.665}
        fill="#ffb69e"
        transform="rotate(-67.48 438.299 91.541)"
        r={33.456}
      />
      <path
        d="M510.534 318.946L480.721 156.01c-1.811-9.899-9.798-17.019-19.127-17.019h-33.728c-9.31 0-17.319 7.14-19.127 17.019l-29.813 162.936c-.482 2.634.149 5.364 1.724 7.44s3.926 3.279 6.409 3.279h115.343c2.481 0 4.832-1.211 6.406-3.285 1.574-2.075 2.208-4.802 1.726-7.434z"
        fill="#ff6473"
      />
      <g>
        <path
          d="M463.574 199.695l-13.552-2.342-17.11 6.557 1.597 16.634-29.257 57.654c-3.915 7.714-.835 17.141 6.879 21.056 7.703 3.909 17.136.846 21.056-6.879l31.315-61.71a15.662 15.662 0 001.624-8.585z"
          fill="#ffb69e"
        />
        <path
          d="M432.912 203.911l30.662-4.215-3.251-38.088c-.827-8.611-8.484-14.925-17.089-14.096-8.611.827-14.922 8.477-14.096 17.089z"
          fill="#ff4a5b"
        />
      </g>
    </svg>
  )
}

export default SvgComponent