import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			width={80}
      height={80}
			{...props}>
      <g fill="#aab2bd">
        <path d="M131.988 291.993a40.564 40.564 0 01-3.12 15.598c-6.077 14.316-20.315 24.393-36.87 24.393-15.196 0-28.392-8.482-35.191-20.957-3.039-5.678-4.8-12.152-4.8-19.033 0-22.074 17.917-39.991 39.991-39.991 22.073-.001 39.99 17.916 39.99 39.99zM403.924 252.002c22.073 0 39.991 17.917 39.991 39.991a40.561 40.561 0 01-3.116 15.598c-6.077 14.316-20.324 24.393-36.875 24.393-17.917 0-33.109-11.842-38.232-28.072-1.117-3.764-1.758-7.764-1.758-11.918 0-22.075 17.918-39.992 39.99-39.992z" />
      </g>
      <path
        d="M499.901 260.001v7.998l-23.994-7.998V246.406c18.238 7.838 23.994 13.595 23.994 13.595z"
        fill="#e6e9ed"
      />
      <path
        d="M371.932 220.01c52.87 8.158 85.098 18.315 103.976 26.396V260.001l23.994 7.998v15.996l8.076 7.998-8.076 7.998v7.998h-47.988l-11.114-.398a40.561 40.561 0 003.116-15.598c0-22.074-17.918-39.991-39.991-39.991-22.072 0-39.99 17.917-39.99 39.991 0 4.154.641 8.154 1.758 11.918l-9.756 4.078H139.986l-11.118-.398c2-4.805 3.12-10.084 3.12-15.598 0-22.074-17.917-39.991-39.99-39.991s-39.991 17.917-39.991 39.991c0 6.881 1.761 13.355 4.8 19.033l-12.798-3.037s-27.994 0-31.993-23.994c-3.28-19.676-7.998-39.991-7.998-39.991h39.991l-23.995-15.996v-7.998l71.983-7.999s10.478-5.999 30.071-12.797v.082l9.919 4.717s15.996 15.997 223.947 15.997h15.998z"
        fill="#ed5565"
      />
      <path
        d="M52.007 307.989s-27.993 0-31.993-23.994c-3.28-19.676-7.998-39.991-7.998-39.991l15.997-15.996v-7.998l71.983-7.999s8.889-5.045 25.432-11.119l-3.358-1.597v-.082c-19.593 6.799-30.071 12.797-30.071 12.797l-71.983 7.999v7.998L4.019 244.004s4.718 20.315 7.998 39.991c3.999 23.994 31.993 23.994 31.993 23.994l12.798 3.037c-.367-.686-.656-1.42-.984-2.131l-3.817-.906z"
        fill="#fff"
        opacity={0.3}
      />
      <path
        d="M499.901 283.995v-23.994s-5.757-5.757-23.994-13.595c-18.878-8.08-103.976-26.396-103.976-26.396h-21.744s97.469 19.999 116.347 28.079c18.238 7.838 25.369 14.246 25.369 14.246v21.66l8.076 7.998-8.076 7.998v7.998h7.998v-7.998l8.076-7.998-8.076-7.998z"
        opacity={0.2}
      />
      <path
        d="M243.962 180.019c53.987 0 108.857 28.712 127.97 39.991h-15.996c-207.951 0-223.947-15.997-223.947-15.997l-9.919-4.717v-.082c25.755-8.877 67.424-19.195 121.892-19.195z"
        fill="#5d9cec"
      />
      <path
        d="M268.659 218.862l9.475-35.343c-5.265-1.007-10.56-1.835-15.918-2.425l-9.975 37.222c5.249.194 10.7.378 16.418.546zM276.884 219.084l9.092-33.938a273.688 273.688 0 00-3.913-.855l-9.295 34.691c1.328.036 2.757.067 4.116.102z"
        fill="#fff"
        opacity={0.3}
      />
      <path fill="#e6e9ed" d="M20.015 228.008l23.994 15.996H4.019z" />
      <path fill="#fff" d="M24.814 231.207l-4.799-3.199-15.996 15.996h7.998z" />
      <path
        d="M60.006 291.993c0-20.707 15.82-37.55 35.991-39.588-1.336-.133-2.628-.402-3.999-.402-22.073 0-39.991 17.917-39.991 39.991 0 22.15 17.917 39.99 39.991 39.99 1.371 0 2.663-.266 3.999-.406-20.171-2.024-35.991-18.809-35.991-39.585z"
        fill="#fff"
        opacity={0.5}
      />
      <path
        d="M123.99 291.993c0 20.705-15.82 37.553-35.991 39.584 1.335.141 2.628.406 3.999.406 22.073 0 39.99-17.918 39.99-39.99 0-22.152-17.917-39.991-39.99-39.991-1.371 0-2.664.27-3.999.402 20.171 2.027 35.991 18.812 35.991 39.589z"
        opacity={0.2}
      />
      <path
        d="M371.932 291.993c0-20.707 15.816-37.55 35.992-39.588-1.336-.133-2.633-.402-4-.402-22.072 0-39.99 17.917-39.99 39.991 0 22.15 17.918 39.99 39.99 39.99 1.367 0 2.664-.266 4-.406-20.176-2.024-35.992-18.809-35.992-39.585z"
        fill="#fff"
        opacity={0.5}
      />
      <path
        d="M435.916 291.993c0 20.705-15.816 37.553-35.991 39.584 1.336.141 2.633.406 3.999.406 22.073 0 39.991-17.918 39.991-39.99 0-22.152-17.918-39.991-39.991-39.991-1.366 0-2.663.27-3.999.402 20.175 2.027 35.991 18.812 35.991 39.589z"
        opacity={0.2}
      />
      <g fill="#3a3847">
        <path d="M43.99 311.987c-10.853 0-32.333-5.717-35.937-27.336-3.222-19.324-7.9-39.542-7.947-39.741a4 4 0 011.066-3.734l14.824-14.825v-6.342a4 4 0 013.558-3.976l71.182-7.908c7.237-3.948 62.052-32.106 153.206-32.106 68.651 0 122.116 35.098 129.497 40.178 103.304 16.012 128.251 39.959 129.266 40.975a3.998 3.998 0 011.172 2.828v22.322l6.928 6.826c.758.75 1.195 1.773 1.195 2.844s-.438 2.094-1.195 2.842l-8.115 7.998a3.982 3.982 0 01-5.654-.031 4.003 4.003 0 01.039-5.654l5.225-5.154-5.225-5.156a4 4 0 01-1.195-2.842v-22.133c-5.904-4.561-35.726-24.231-124.58-37.9a4.054 4.054 0 01-1.71-.695c-.555-.395-55.854-39.249-125.646-39.249-93.649 0-149.43 31.153-149.984 31.465-.473.27-.996.441-1.539.504l-68.425 7.604v4.417a3.999 3.999 0 01-1.171 2.827L8.389 245.27c1.301 5.788 4.909 22.214 7.553 38.069 3.402 20.416 27.045 20.65 28.048 20.65 2.21 0 3.999 1.789 3.999 4s-1.789 3.998-3.999 3.998z" />
        <path d="M43.986 248.003a3.996 3.996 0 01-2.214-.672l-23.994-15.996a3.996 3.996 0 01-1.109-5.546 3.991 3.991 0 015.546-1.109l23.994 15.997a3.996 3.996 0 011.109 5.545 3.996 3.996 0 01-3.332 1.781z" />
        <path d="M43.99 248.003H3.999A3.997 3.997 0 010 244.004a3.997 3.997 0 013.999-3.999H43.99a3.997 3.997 0 013.999 3.999 3.997 3.997 0 01-3.999 3.999zM203.956 211.754a3.99 3.99 0 01-3.62-2.297l-7.999-16.98a3.996 3.996 0 011.914-5.319c1.988-.957 4.378-.085 5.319 1.914l7.998 16.98a3.995 3.995 0 01-1.914 5.319 3.933 3.933 0 01-1.698.383zM227.946 248.003H211.95a3.997 3.997 0 01-3.999-3.999 3.997 3.997 0 013.999-3.999h15.996a3.997 3.997 0 013.999 3.999 3.997 3.997 0 01-3.999 3.999zM92.217 295.991c-2.207 0-4.038-1.787-4.038-3.998s1.75-4 3.96-4h.078c2.21 0 3.999 1.789 3.999 4s-1.789 3.998-3.999 3.998z" />
        <path d="M91.979 335.981c-24.256 0-43.99-19.729-43.99-43.988s19.734-43.99 43.99-43.99 43.989 19.73 43.989 43.99-19.734 43.988-43.989 43.988zm0-79.98c-19.847 0-35.991 16.144-35.991 35.992 0 19.846 16.145 35.99 35.991 35.99s35.991-16.145 35.991-35.99c0-19.847-16.145-35.992-35.991-35.992z" />
        <path d="M91.979 279.995a3.997 3.997 0 01-3.999-3.998v-7.998c0-2.211 1.789-4 3.999-4s3.999 1.789 3.999 4v7.998a3.997 3.997 0 01-3.999 3.998zM91.979 319.985a3.996 3.996 0 01-3.999-3.998v-7.998c0-2.211 1.789-4 3.999-4s3.999 1.789 3.999 4v7.998a3.996 3.996 0 01-3.999 3.998zM75.982 295.991h-7.998c-2.211 0-3.999-1.787-3.999-3.998s1.788-4 3.999-4h7.998c2.21 0 3.999 1.789 3.999 4s-1.789 3.998-3.999 3.998zM115.973 295.991h-7.998c-2.21 0-3.999-1.787-3.999-3.998s1.789-4 3.999-4h7.998c2.21 0 3.999 1.789 3.999 4s-1.789 3.998-3.999 3.998zM80.669 284.683a3.988 3.988 0 01-2.828-1.172l-5.654-5.654a4 4 0 015.654-5.656l5.655 5.656a3.997 3.997 0 01-2.827 6.826zM108.943 312.956a3.988 3.988 0 01-2.828-1.172l-5.655-5.654a3.998 3.998 0 115.655-5.654l5.655 5.654a3.997 3.997 0 01-2.827 6.826zM75.014 312.956a3.997 3.997 0 01-2.827-6.826l5.654-5.654a3.998 3.998 0 115.655 5.654l-5.655 5.654a3.986 3.986 0 01-2.827 1.172zM103.288 284.683a3.997 3.997 0 01-2.827-6.826l5.655-5.656a4 4 0 015.655 5.656l-5.655 5.654a3.987 3.987 0 01-2.828 1.172zM404.143 295.991c-2.21 0-4.038-1.787-4.038-3.998s1.75-4 3.96-4h.078c2.211 0 3.999 1.789 3.999 4s-1.788 3.998-3.999 3.998z" />
        <path d="M403.9 335.981c-24.252 0-43.989-19.729-43.989-43.988s19.737-43.99 43.989-43.99c24.26 0 43.99 19.73 43.99 43.99s-19.73 43.988-43.99 43.988zm0-79.98c-19.839 0-35.991 16.144-35.991 35.992 0 19.846 16.152 35.99 35.991 35.99 19.855 0 35.992-16.145 35.992-35.99.001-19.847-16.136-35.992-35.992-35.992z" />
        <path d="M403.9 279.995a4.002 4.002 0 01-3.999-3.998v-7.998c0-2.211 1.797-4 3.999-4 2.219 0 4 1.789 4 4v7.998a3.992 3.992 0 01-4 3.998zM403.9 319.985a4 4 0 01-3.999-3.998v-7.998c0-2.211 1.797-4 3.999-4 2.219 0 4 1.789 4 4v7.998a3.99 3.99 0 01-4 3.998zM387.904 295.991h-7.998a4 4 0 01-3.999-3.998c0-2.211 1.797-4 3.999-4h7.998c2.219 0 3.999 1.789 3.999 4s-1.78 3.998-3.999 3.998zM427.895 295.991h-7.998a4 4 0 010-7.998h7.998c2.219 0 4 1.789 4 4s-1.782 3.998-4 3.998zM392.591 284.683a3.998 3.998 0 01-2.827-1.172l-5.655-5.654a4 4 0 015.655-5.656l5.654 5.656a3.997 3.997 0 010 5.654 3.972 3.972 0 01-2.827 1.172zM420.865 312.956a3.998 3.998 0 01-2.827-1.172l-5.655-5.654a3.998 3.998 0 115.655-5.654l5.655 5.654a3.997 3.997 0 010 5.654 3.975 3.975 0 01-2.828 1.172zM386.936 312.956a4 4 0 01-2.827-6.826l5.655-5.654a3.997 3.997 0 015.654 0 3.997 3.997 0 010 5.654l-5.654 5.654a3.976 3.976 0 01-2.828 1.172zM415.211 284.683a4 4 0 01-2.828-6.826l5.655-5.656a4 4 0 015.655 5.656l-5.655 5.654a3.972 3.972 0 01-2.827 1.172zM355.912 224.009c-203.722 0-225.013-15.411-226.771-17.168a3.998 3.998 0 010-5.655 3.992 3.992 0 015.389-.242c1.449.953 26.306 15.067 221.381 15.067a3.99 3.99 0 013.999 3.999 3.99 3.99 0 01-3.998 3.999zM355.912 311.987H139.967c-2.21 0-3.999-1.787-3.999-3.998s1.789-4 3.999-4h215.945c2.219 0 3.999 1.789 3.999 4s-1.78 3.998-3.999 3.998zM508.001 311.987H451.89a4 4 0 010-7.998h56.111a4 4 0 010 7.998zM499.878 271.997c-.414 0-.844-.062-1.265-.203l-23.995-8.002c-2.093-.695-3.218-2.959-2.522-5.057a3.99 3.99 0 015.054-2.527l23.994 7.994c2.093.703 3.233 2.969 2.53 5.062a4.007 4.007 0 01-3.796 2.733z" />
        <path d="M475.884 263.999a4.002 4.002 0 01-3.999-3.998v-13.568a4.002 4.002 0 013.999-3.999 3.992 3.992 0 013.999 3.999v13.568a3.992 3.992 0 01-3.999 3.998z" />
      </g>
    </svg>
  )
}

export default SvgComponent